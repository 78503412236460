import "../App.css";
import React from "react";

function FrostBlight() {
  return (
    <div>
      <h1>Frost Blight Content</h1>
    </div>
  );
}

export default FrostBlight;
