import React from "react";
import "../../App.css";
import { Card, Button } from "react-bootstrap";

function WebGameCard(props) {
  return (
    <Card
      style={{
        width: "15rem",
        height: "100%",
        backgroundImage:
          "linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.5) 10%, rgba(255,255,255,0.8) 25%, rgba(255,255,255,0.8) 75%, rgba(255,255,255,0.5) 90%, rgba(255,255,255,0) 100%)",
        backgroundColor: " rgba(0,0,0,0)",
      }}
    >
      <Card.Img variant="top" src={props.thumbnail} />
      <Card.Body>
        <Card.Title>{props.title}</Card.Title>
        <p style={{ fontWeight: "bold" }}>{props.description}</p>
      </Card.Body>
      <Button onClick={props.changeGame} variant="success" size="lg" style={{ width: "100%", margin: "auto" }}>
        Play
      </Button>
    </Card>
  );
}

export default WebGameCard;
