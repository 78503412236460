import "../App.css";
import React from "react";
import { Nav, Row } from "react-bootstrap";
import WebGameSelect from "../components/web_games/WebGameSelect";
import "bootstrap/dist/css/bootstrap.css";
import Tab from "react-bootstrap/Tab";

function Games() {
  return (
    <div>
      <Tab.Container id="games-view" defaultActiveKey="second">
        <Row>
          <Nav variant="pills">
            <Nav.Item>
              <Nav.Link eventKey="first" style={{ color: "white", fontWeight: "bold" }}>
                Downloadable Games
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="second" style={{ color: "white", fontWeight: "bold" }}>
                Web Games
              </Nav.Link>
            </Nav.Item>
          </Nav>
        </Row>

        <Row>
          <Tab.Content>
            <Tab.Pane eventKey="first">Downloadable Games will go here</Tab.Pane>

            <Tab.Pane eventKey="second">
              <WebGameSelect />
            </Tab.Pane>
          </Tab.Content>
        </Row>
      </Tab.Container>
    </div>
  );
}

export default Games;
