import "../App";
import React from "react";
import { Link } from "react-router-dom";

function Nav() {
  return (
    <div className="nav">
      <Link exact activeClassName="btn-nav-active" to="/frostblight">
        <button type="button" class="btn  btn-nav-default">
          Frost Blight
        </button>
      </Link>

      <Link to="/games">
        <button type="button" class="btn btn-nav-default">
          Games
        </button>
      </Link>

      <Link to="/about">
        <button type="button" class="btn  btn-nav-default">
          About
        </button>
      </Link>

      <Link to="/contact">
        <button type="button" class="btn  btn-nav-default">
          Contact
        </button>
      </Link>
    </div>
  );
}

export default Nav;
