import "./App.css";
import React from "react";
import { Route, Switch, Link, withRouter } from "react-router-dom";

import logo from "./media/Wammoh_Logo.png";
import Nav from "./components/Nav";
import Home from "./content_pages/Home";
import Games from "./content_pages/Games";
import FrostBlight from "./content_pages/FrostBlight";
import About from "./content_pages/About";
import Contact from "./content_pages/Contact";

function App() {
  return (
    <div className="container">
      <Link to="/">
        <div className="logo">
          <img src={logo} width={"90%"} alt="Logo" />
        </div>
      </Link>
      <Nav />

      <div className="sidebar"></div>

      <div className="content">
        <Switch>
          <Route path="/games" exact component={Games} />
          <Route path="/frostblight" exact component={FrostBlight} />
          <Route path="/about" exact component={About} />
          <Route path="/contact" exact component={Contact} />
          <Route path="/" exact component={Home} />
        </Switch>
      </div>

      <div className="footer">Footer</div>
    </div>
  );
}

const mapStateProps = (state) => {};

export default App;
