import React, { useState } from "react";
import "../../App.css";
import { Card, Button, Row, Col } from "react-bootstrap";
import WebGameCard from "./WebGameCard";

import slipknightThumbnail from "../../media/game_images/SlipKnight_Thumbnail.png";
import CoronaGroceryDashThumbnail from "../../media/game_images/CoronaGroceryDash_Thumbnail.png";
import CleanHouseThumbnail from "../../media/game_images/CleanHouse_Thumbnail.png";
import FamilyDoctorThumbnail from "../../media/game_images/FamilyDoctor_Thumbnail.png";
import FlappyHeroesThumbnail from "../../media/game_images/FlappyHeroes_Thumbnail.png";

function WebGameSelect() {
  const [game, setGame] = useState("");
  const [source, setSource] = useState("");
  const [hRef, setHref] = useState("");
  const [width, setWidth] = useState("0");
  const [height, setHeight] = useState("0");

  const SlipKnight = {
    name: "Slip Knight",
    source: "https://itch.io/embed-upload/1350201?color=000000",
    hReference: "https://wammoh.itch.io/slipknight",
    description: "Slide your way around the arena and defeat your foes!",
    width: "980",
    height: "680",
  };

  const CoronaGroceryDash = {
    name: "Corona Grocery Dash",
    source: "https://itch.io/embed-upload/2099112?color=dfe5c7",
    hReference: "https://wammoh.itch.io/corona-grocery-dash",
    description: "Slide your way around the arena and defeat your foes!",
    width: "1044",
    height: "808",
  };

  const CleanHouse = {
    name: "Clean House",
    source: "https://itch.io/embed-upload/1533286?color=333333",
    hReference: "https://wammoh.itch.io/clean-house",
    description: "A relaxing item finding game.",
    width: "1280",
    height: "740",
  };

  const FamilyDoctor = {
    name: "Family Doctor",
    source: "https://itch.io/embed-upload/1299441?color=a5bac0",
    hReference: "hhttps://wammoh.itch.io/family-doctor",
    description: "Can you diagnose the sick?",
    width: "1044",
    height: "808",
  };

  const FlappyHeroes = {
    name: "Flappy Heroes",
    source: "https://itch.io/embed-upload/1552567?color=333333",
    hReference: "https://wammoh.itch.io/flappy-heroes",
    description: "A clone of the classic flappy bird game. An unfinished mobile game brought to web.",
    width: "1024",
    height: "596",
  };

  return (
    <div>
      <Row
        xs="auto"
        style={{
          backgroundImage: "linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 50%, rgba(0,0,0,0) 100%)",
          alignSelf: "center",
          justifyContent: "center",
          display: "flex",
        }}
      >
        {/*SLIP KNIGHT*/}
        <Col>
          <WebGameCard
            thumbnail={slipknightThumbnail}
            title={SlipKnight.name}
            description={SlipKnight.description}
            changeGame={() => {
              setGame(SlipKnight.name);
              setSource(SlipKnight.source);
              setHref(SlipKnight.hReference);
              setWidth(SlipKnight.width);
              setHeight(SlipKnight.height);
            }}
          />
        </Col>
        {/*CORONA GROCERY DASH*/}
        <Col>
          <WebGameCard
            thumbnail={CoronaGroceryDashThumbnail}
            title={CoronaGroceryDash.name}
            description={CoronaGroceryDash.description}
            changeGame={() => {
              setGame(CoronaGroceryDash.name);
              setSource(CoronaGroceryDash.source);
              setHref(CoronaGroceryDash.hReference);
              setWidth(CoronaGroceryDash.width);
              setHeight(CoronaGroceryDash.height);
            }}
          />
        </Col>
        {/*CLEAN HOUSE*/}
        <Col>
          <WebGameCard
            thumbnail={CleanHouseThumbnail}
            title={CleanHouse.name}
            description={CleanHouse.description}
            changeGame={() => {
              setGame(CleanHouse.name);
              setSource(CleanHouse.source);
              setHref(CleanHouse.hReference);
              setWidth(CleanHouse.width);
              setHeight(CleanHouse.height);
            }}
          />
        </Col>
        {/*FAMILY DOCTOR*/}
        <Col>
          <WebGameCard
            thumbnail={FamilyDoctorThumbnail}
            title={FamilyDoctor.name}
            description={FamilyDoctor.description}
            changeGame={() => {
              setGame(FamilyDoctor.name);
              setSource(FamilyDoctor.source);
              setHref(FamilyDoctor.hReference);
              setWidth(FamilyDoctor.width);
              setHeight(FamilyDoctor.height);
            }}
          />
        </Col>
        {/*FLAPPY HEROES*/}
        <Col>
          <WebGameCard
            thumbnail={FlappyHeroesThumbnail}
            title={FlappyHeroes.name}
            description={FlappyHeroes.description}
            changeGame={() => {
              setGame(FlappyHeroes.name);
              setSource(FlappyHeroes.source);
              setHref(FlappyHeroes.hReference);
              setWidth(FlappyHeroes.width);
              setHeight(FlappyHeroes.height);
            }}
          />
        </Col>
      </Row>
      {/*EXTRA SPACE BETWEEN CARDS AND GAME VIEW*/}
      <Row>
        <div style={{ width: "100%", height: "25px", backgroundColor: "black" }}></div>
      </Row>
      <Row>
        {/*iframe for game goes here*/}
        <div style={{ alignSelf: "center", alignItems: "center", justifyContent: "center", display: "flex", backgroundColor: "black" }}>
          <iframe src={source} width={width} height={height}>
            <a href={hRef}></a>
          </iframe>
        </div>
      </Row>
      {/*EXTRA SPACE BETWEEN CARDS AND GAME VIEW*/}
      <Row>
        <div style={{ width: "100%", height: "200px", backgroundImage: "linear-gradient(0deg, rgba(0,0,0,0) 0%,  rgba(0,0,0,1) 100%)" }}></div>
      </Row>
    </div>
  );
}

export default WebGameSelect;
