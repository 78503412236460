import "../App.css";
import React from "react";

function Home() {
  return (
    <div>
      <h1>Home Content</h1>
    </div>
  );
}

export default Home;
